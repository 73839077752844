import moment from 'moment';

// @vue/component
export default {
    computed: {
        /**
         * @returns {string} Localized Moment date format.
         */
        format() {
            return moment.localeData().longDateFormat('L');
        },

        /**
         * @returns {string} Date format converted to ElementUI date format.
         */
        formatConverted() {
            return this.format
                .replace('YYYY', 'yyyy')
                .replace('DD', 'dd')
                .replace('D', 'd');
        },

        /**
         * @returns {string} Date format converted to regex pattern.
         */
        pattern() {
            return this.format
                .replace('YYYY', '[0-9]{4}')
                .replace('MM', '(0[1-9]|1[0-2])')
                .replace('M', '([1-9]|1[0-2])')
                .replace('DD', '(0[1-9]|1[0-9]|2[0-9]|3[01])')
                .replace('D', '([1-9]|1[0-9]|2[0-9]|3[01])')
                .replace('HH', '([01][0-9]|2[0-3])')
                .replace('H', '(1?[0-9]|2[0-3])')
                .replace('mm', '[0-5][0-9]')
                .replace('m', '[1-5]?[0-9]');
        },
    },
};
