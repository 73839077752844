<script>
    import moment from 'moment';
    import DatePicker from './DatePicker';

    export default {
        extends: DatePicker,

        props: {
            type: {
                type: String,
                default: 'datetime',
            },
        },

        computed: {
            /**
             * @returns {string} Localized Moment date format.
             */
            format() {
                return `${moment.localeData().longDateFormat('L')} ${moment.localeData().longDateFormat('LT')}`;
            },
        },
    };
</script>
