<template>
    <div class="form-datepicker">
        <el-date-picker
            :id="id+'_datepicker'"
            ref="datepicker"
            v-model="valueCustom"
            :clearable="false"
            :disabled="disabled"
            :editable="editable"
            :format="formatConverted"
            :picker-options="pickerOptions"
            :placeholder="placeholder"
            :required="required"
            :type="type"
        />
        <input
            :id="id"
            :disabled="disabled"
            :name="name"
            :value="valueFormatted"
            type="hidden"
        >
    </div>
</template>

<script>
    import moment from 'moment';
    import DatePicker from 'element-ui/lib/date-picker';
    import DateFormat from '../../mixins/Inputs/DateFormat';
    import dateFormatter from '../../i18n/dateFormatter';
    import dateParser from '../../i18n/dateParser';

    let counter = 0;

    export default {
        mixins: [DateFormat],

        components: {
            'el-date-picker': DatePicker,
        },

        props: {
            id: {
                type: String,
                default: 'datepicker_' + ++counter,
            },

            type: {
                type: String,
                default: 'date',
            },

            name: {
                type: String,
                default: null,
            },

            value: {
                type: String,
                default: null,
            },

            required: {
                type: Boolean,
                default: false,
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            placeholder: {
                type: String,
                default: null,
            },

            maxValue: {
                type: String,
                default: null,
            },
        },

        data() {
            return {
                valueCustom: new Date(),
                editable: true,
                pickerOptions: {
                    firstDayOfWeek: 1,
                    disabledDate: (date) => {
                        if (!this.maxValue) {
                            return false;
                        }

                        return date > dateParser(this.maxValue);
                    },
                },
            };
        },

        computed: {
            valueFormatted() {
                return this.formatDateAsISO8601(this.valueCustom);
            },
        },

        watch: {
            value(value) {
                this.setValue(value);
            },

            valueCustom(value) {
                const valueAsISO8601 = this.formatDateAsISO8601(value);

                this.$emit('input', valueAsISO8601);
                this.$emit('change', valueAsISO8601);
            },

            required(required) {
                this.$refs.datepicker.$refs.reference.$refs.input.required = required;
            },
        },

        mounted() {
            // Pre-fill
            this.setValue(this.value);

            if (this.required) {
                this.$refs.datepicker.$refs.reference.$refs.input.required = true;
            }

            this.$refs.datepicker.$refs.reference.$refs.input.pattern = this.pattern;
            this.$refs.datepicker.$refs.reference.$refs.input.title = this.$t('masterfiles.messages.hint-date', { date: moment().format(this.format) });

            this.$watch(() => this.$refs.datepicker.$refs.reference.currentValue, newValue => {
                if (newValue === '') {
                    this.$emit('input', null);
                    this.$emit('change', null);
                }
            });

            // Focus input when the associated label is clicked
            document.addEventListener('click', (e) => {
                if (e.target.tagName !== 'LABEL' || e.target.htmlFor !== this.id) {
                    return;
                }

                this.$refs.datepicker && this.$refs.datepicker.focus();
            });
        },

        methods: {
            setValue(value) {
                if (value) {
                    this.valueCustom = dateParser(value);
                } else {
                    this.valueCustom = null;

                    // The datepicker hides the picker when the value is set to null, but we want to keep it visible if it already is.
                    if (this.$refs.datepicker.pickerVisible) {
                        this.$nextTick(() => {
                            this.$refs.datepicker.pickerVisible = true;
                        });
                    }
                }
            },

            formatDateAsISO8601(date) {
                if (!date) {
                    return null;
                }

                return dateFormatter(date);
            },
        },
    };
</script>
